import React from 'react';
import { Link } from 'react-router-dom';
const Contact = () => {
    return (
        <main>
            {/* <!--Page Header Start--> */}
            <section class="page-header">
                <div class="page-header__bg"></div>
                <div class="container">
                    <div class="page-header__inner">
                        <h2>Contact Us</h2>
                        <div class="thm-breadcrumb__box">
                            <ul class="thm-breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li><span>::</span></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            {/* <!--Get In Touch Two Start--> */}
            <section class="get-in-touch-two">
                <div class="container">
                    <div class="section-title text-left">
                        <div class="section-title__tagline-box">
                            <span class="section-title__tagline">. : Have Questions?</span>
                        </div>
                        <h2 class="section-title__title">Get in Touch!</h2>
                    </div>
                    <div class="get-in-touch-two__inner">
                        <ul class="get-in-touch-two__list">
                            <li>
                                <div class="icon">
                                    <span class="icon-location"></span>
                                </div>
                                <div class="content">
                                    <h3>Office Address</h3>
                                    <p>Eacham Pottal Pudur, <br /> Tenkasi, Tamilnadu - 627755</p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <span class="icon-phone-1"></span>
                                </div>
                                <div class="content">
                                    <h3>Phone No.</h3>
                                    <p><a>+91 956 693 2342</a></p>
                                    <p><a>+91 807 273 0120</a></p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <span class="icon-mail-1"></span>
                                </div>
                                <div class="content">
                                    <h3>Mail Address</h3>
                                    <p><a>info@tachtag.in </a></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* <!--Get In Touch Two End--> */}

            {/* <!--Google Map Start--> */}
            <section class="google-map">
                <div class="container">
                    
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d205.46845497842392!2d77.4725695306707!3d9.168911299188643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0697909f9bcea5%3A0x33ddd047ba269687!2sEcham%20Pottal%20Pudur!5e1!3m2!1sen!2sin!4v1720709714982!5m2!1sen!2sin" class="google-map__one" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
            {/* <!--Google Map End--> */}

            {/* <!--Contact Page Start--> */}
            {/* <section class="contact-page">
                <div class="container">
                    <div class="contact-page__inner">
                        <h3 class="contact-page__title">What are you looking for?</h3>
                        <div class="contact-page__form">
                            <form method="post" action="https://themecraze.net/html/vitors/sendemail.php" id="#" class="contact-page__form-box"
                                novalidate="novalidate">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="contact-page__input-box">
                                            <input type="text" name="username" placeholder="Name" required="" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="contact-page__input-box">
                                            <input type="text" name="email" placeholder="Email" required="" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="contact-page__input-box">
                                            <input type="text" name="phone" placeholder="subject" required="" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="contact-page__input-box">
                                            <input type="text" name="phone" placeholder="Phone" required="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="contact-page__input-box text-message-box">
                                            <textarea name="message" placeholder="Massage"></textarea>
                                        </div>
                                        <div class="contact-page__btn-box">
                                            <button type="submit" class="contact-page__btn">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--Contact Page End--> */}
        </main>

    );
};
export default Contact;